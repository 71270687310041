import(/* webpackMode: "eager" */ "/home/runner/work/deployment/deployment/limbar-io/app/css/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/deployment/deployment/limbar-io/app/openreplay-tracker.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/deployment/deployment/limbar-io/app/zaraz-init.ts");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/home/runner/work/deployment/deployment/limbar-io/node_modules/next-themes/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/deployment/deployment/limbar-io/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/deployment/deployment/limbar-io/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"JetBrains_Mono\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-jetbrains-mono\"}],\"variableName\":\"jetbrainsMono\"}");
